<template>
  <div>
    <search-tenant-orders @submit="submitSearch" />
    <a-table
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
      @change="sortChange"
    >
      <div slot="order_no" slot-scope="order_no, record">
        <a @click="showInfoModal(record)">{{ order_no }}</a>
      </div>
      <div slot="dead_state" slot-scope="dead_state, record">
        <span v-if="record.record_type_slug !== 'service' ">-</span>
        <span v-else-if="dead_state">已逝世</span>
        <span v-else>在世</span>
      </div>
      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a @click="showOperationHistoryModal(record)">操作历史</a>
        </a-space>
      </span>
    </a-table>
    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />
    <!-- 详情模态框 -->
    <show-order
      v-if="isShowInfoModal"
      :visible.sync="isShowInfoModal"
      :record-id="orderRecord.record_id"
      :id="showingId"
      :order-type="orderType"
      @completed="fetchData"
    />
    <!--操作历史 -->
    <operation-history-list-modal
      v-if="isShowOperationHistoryModal"
      :reference-id="serviceOrderId"
      :reference-type="historyOrderType"
      :visible.sync="isShowOperationHistoryModal"
    />
  </div>
</template>

<script>
import {
  findSystemOrdersList
} from '@/api/order'
import SearchTenantOrders from '@/views/orders/system/Search'
import Pagination from '@/components/Pagination'

export default {
  name: 'OrderTenantList',
  components: {
    Pagination,
    SearchTenantOrders,
    ShowOrder: () => import('@/views/orders/system/Show'),
    OperationHistoryListModal: () => import('@/views/operation_histories/Modal')
  },
  data() {
    return {
      query: {},
      data: [],
      isShowInfoModal: false, // 是否显示详情弹窗
      isShowOperationHistoryModal: false,
      loading: true,
      showingId: 0,
      deathName: '',
      orderType: '',
      historyOrderType: '',
      orderRecord: {},
      orderStatus: '',
      pagination: {
        total_count: 0
      },
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '订单号',
          dataIndex: 'order_no',
          scopedSlots: { customRender: 'order_no' },
          fixed: 'left',
          width: 180
        },
        {
          title: '订单创建时间',
          dataIndex: 'created_at',
          width: 200,
          sorter: true,
          sortOrder: this.sort.sort_field === 'created_at' ? this.sort.sort_order : false
        },
        {
          title: '订单类型',
          dataIndex: 'record_type_display',
          width: 120
        },
        {
          title: '订单状态',
          dataIndex: 'order_status',
          width: 120
        },
        {
          title: '逝者状态',
          width: 90,
          dataIndex: 'dead_state',
          scopedSlots: { customRender: 'dead_state' }
        },
        {
          title: '订单来源',
          width: 90,
          dataIndex: 'source'
        },
        {
          title: '申请人',
          width: 120,
          dataIndex: 'apply_name'
        },
        {
          title: '联系电话',
          width: 180,
          dataIndex: 'phone_number'
        },
        {
          title: '负责人',
          width: 120,
          dataIndex: 'head_name'
        },
        {
          title: '任务数量',
          width: 120,
          dataIndex: 'task_count'
        },
        {
          title: '所属租户',
          width: 120,
          dataIndex: 'tenant_name'
        },
        {
          title: '操作',
          width: 150,
          dataIndex: 'actions',
          fixed: 'right',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    }
  },
  methods: {
    showOperationHistoryModal(record) {
      this.serviceOrderId = record.service_order_id
      this.isShowOperationHistoryModal = true
      if (record.record_type_slug === 'service') {
        this.historyOrderType = 'ServiceOrder'
      } else if (record.record_type_slug === 'subscribe') {
        this.historyOrderType = 'SubscribeOrder'
      } else {
        this.historyOrderType = 'AdditionalItemOrder'
      }
    },
    showInfoModal(record) {
      this.showingId = record.id
      this.orderType = record.record_type_slug
      this.orderStatus = record.order_status_slug
      this.orderRecord = record
      this.isShowInfoModal = true
    },
    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },
    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findSystemOrdersList(Object.assign(this.query, this.sort)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    }
  }
}
</script>
